import React, { Component} from "react";
import "./HomePage.css";
import {
  Jumbotron,
  Image,
  Button,
  Card,
  Col,
  Container,
  Row,
  CardGroup,
} from "react-bootstrap";
import { init } from "ityped";
import AOS from "aos";
import {Helmet} from 'react-helmet';

class HomePage extends Component {
  componentDidMount() {
    const nameScroll = document.querySelector("#nameScroll");
    init(nameScroll, {
      showCursor: false,
      strings: ["MORE THAN JUST A S̶C̶R̶I̶B̶E̶ ̶S̶E̶R̶V̶I̶C̶E̶."],
      typeSpeed: 30,
      loop: false,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    //creates a reference for your element to use
    this.myDivToFocus = React.createRef();
  }

  handleOnClick = (event) => {
    //.current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  render() {
    AOS.init();
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home Page | PHYSIMAX LLC</title>
          <meta name="description" content="PHYSIMAX LLC, an innovating electronic medical record (EMR) filing service" />
          <meta name="robots" content="index, follow" />
          
        </Helmet>
        <div
          className="jumbotron jumbotron-fluid jumbotron-hero vertical-center text-center animation-solidify"
          style={{ marginBottom: "0px" }}
        >
          <div class="hero-text">
            <h1-header id="nameScroll" class="text-center"></h1-header>
            <div>
              <Button
                variant="outline-light"
                onClick={this.handleOnClick}
                size="lg"
                style={{ marginTop: "10vh", width: "25vh" }}
              >
                <h1 style={{ fontSize: "5vh" }}>
                  <i class="fas fa-angle-down"></i>
                </h1>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div
            className="expert-team-section animation-slide-in"
          >
            <Container fluid mb-auto className="margin-bottomcustom">
              <Row>
                <Col md={7} lg={4} style={{ marginBottom: "4vh" }}>
                  <Image
                    width="100%"
                    fluid
                    src="https://i.imgur.com/usqPTB0.jpg"
                  ></Image>
                </Col>
                <Col>
                  <h2 style={{ marginBottom: "3vh" }}>
                    An Expert Team of EMR Specialists
                  </h2>
                  <h4 style={{ marginBottom: "3vh" }} ref={this.myDivToFocus}>
                    Solutions that optimize physician workflow and guarantee
                    increased provider productivity.
                  </h4>
                  <h4>
                    Let our Physician Support Specialist serve as the Interface
                    between You and the EMR.
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            style={{
              backgroundColor: "black",
              paddingLeft: "4vh",
              paddingRight: "4vh",
              paddingTop: "3vh",
            }}
          >
            <Container fluid mb-auto className="margin-bottomcustom">
              <Row>
                <Col style={{ color: "white" }}>
                  <h2 ref={this.myDivToFocus} style={{ marginBottom: "3vh", color: "white" }}>
                    The Physimax LLC Platform.
                  </h2>
                  <h2 style={{ marginBottom: "5vh", color: "white" }}>
                    Optimized solutions tailored to your practice needs.
                  </h2>
                  <h4 style={{ marginBottom: "5vh" }} >
                    To ensure you get the most from the Physimax platform, we
                    provide a range of remote and live support services. Our
                    service portfolio covers every stage of the clinician’s
                    workflow, from census tracking, delinquent medical record
                    and query resolution, past medical history review, data
                    analysis, medical documentation, and other tasks as needed.
                  </h4>
                  <Button size="lg" style={{padding: "1vh", marginBottom: "3vh"}} href="/services">See Our Services <i class="fas fa-play-circle"></i></Button>{" "}
                </Col>
                <Col md={6} lg={6} sm={12} style={{ marginBottom: "4vh" }}>
                  <Image
                    fluid
                    style={{margin: "2vh"}}
                    src="https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  ></Image>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className="card-section-backgrounds"
            data-aos="fade-up"
            data-aos-duration="650"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <Container
              fluid
              className="mt-auto margin-bottomcustom center-text"
            >
              <h2>Why Physimax LLC?</h2>
              <CardGroup className="margin-top-cards">
                <Card>
                  <Card.Body>
                    <h2>
                      <div className="center-text">
                        <i style={{ color: "green" }} class="fas fa-leaf"></i>
                      </div>
                    </h2>
                    <Card.Title className="text-center">
                      REDUCED PROVIDER BURNOUT
                    </Card.Title>
                    <h3 class="center-text">-</h3>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <h2>
                      <div className="center-text">
                        <i
                          style={{ color: "orange" }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{ color: "orange" }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{ color: "orange" }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{ color: "orange" }}
                          className="fas fa-star"
                        ></i>
                        <i style={{ color: "orange" }} class="fas fa-star"></i>
                      </div>
                    </h2>
                    <Card.Title className="text-center">
                      IMPROVED QUALITY OF PATIENT CARE
                    </Card.Title>
                    <h3 className="center-text">-</h3>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <h2>
                      <div className="center-text">
                        <i
                          style={{ color: "blue" }}
                          className="fas fa-thumbs-up"
                        ></i>
                      </div>
                    </h2>
                    <Card.Title className="text-center">
                      INCREASED PATIENT SATISFACTION
                    </Card.Title>
                    <h3 className="center-text">-</h3>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <h2>
                      <div className="center-text">
                        <i
                          style={{ color: "#90EE90" }}
                          className="fas fa-money-check-alt"
                        ></i>
                      </div>
                    </h2>
                    <Card.Title className="text-center">
                      INCREASED LEVEL OF BILLING
                    </Card.Title>
                    <h3 className="center-text">-</h3>
                  </Card.Body>
                </Card>
              </CardGroup>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
