import HomePage from "components/pages/HomePage";
import { BrowserRouter as Router, Route} from 'react-router-dom';
import 'components/Navbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import Nbar from "components/Navbar";
import Footer from 'components/footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';  
import OurServices from 'components/pages/Services';
import ContactPage from 'components/pages/ContactPage';

function App() {
  AOS.init();
  return (
    <div>
    <Nbar></Nbar>
    <Router>
        <Route path='/' exact component={HomePage} />
        <Route path='/services' exact component={OurServices} />
        <Route path='/contact' exact component={ContactPage}></Route>
    </Router>
    <Footer/>
    </div>
  );
}

export default App;