import React, { Component } from "react";
import "./Footer.css";
import {
  Jumbotron,
  Image,
  Card,
  Col,
  Container,
  Row,
  CardGroup,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer class="mainfooter" role="contentinfo">
        <div class="footer-middle">
          <Container style={{ width: "80%" }}>
            <Row>
              <Col>
                <div class="footer-pad">
                  <h4>Site Navigation</h4>
                  <ul class="list-unstyled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <div class="footer-pad">
                  <h4>Contact Us</h4>
                  <ul class="list-unstyled">
                    <li>
                      <a href="mailto:ewills@physimax.org">
                        Email: ewills@physimax.org
                      </a>
                    </li>
                    <li>
                      <a href="tel:727-247-4228">Phone: +1 (727) 247-4228</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} style={{ marginBottom: "3vh" }}>
                <h4>Follow Us</h4>
                <ul class="social-network social-circle">
                  <li>
                    <button href="#" class="icoFacebook" title="Facebook">
                      <i class="fa fa-facebook"></i>
                    </button>
                  </li>
                  <li>
                    <button href="#" class="icoLinkedin" title="Linkedin">
                      <i class="fa fa-linkedin"></i>
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={12} class="copy" style={{ marginTop: "2.25" }}>
                <p class="text-center">
                  &copy; Copyright 2021 - PHYSIMAX LLC. All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

export default Footer;
