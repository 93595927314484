import React, { Component } from "react";
import "./HomePage.css";
import "./Services.css";
import { Image, Col, Container, Row } from "react-bootstrap";
import "./doctor-ipad.jpg";
import smartphoneHand from "./hand-smartphone.jpg";
import { Helmet } from "react-helmet";

class ContactPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact | PHYSIMAX LLC</title>
          <meta
            name="description"
            content="Contact info for Physimax LLC, an innovating EMR filing service"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Container
          style={{ backgroundColor: "black", height: "86vh" }}
          fluid
          mb-auto
        >
          <Row>
            <Col style={{ color: "white", textAlign: "center" }}>
              <div>
                <a href="tel:727-247-4228" style={{ color: "white" }}>
                  <h3>Phone: (727) 247-4228</h3>
                </a>
              </div>
              <a href="mailto:ewills@physimax.org" style={{ color: "white" }}>
                <h3>Email: ewills@physimax.org </h3>{" "}
              </a>
            </Col>
            <Col md={6} lg={6} sm={12} style={{ marginTop: "2vh" }}>
              <Image
                fluid
                style={{ margin: "2vh" }}
                src={smartphoneHand}
                style={{ width: "100%", height: "100%" }}
              ></Image>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactPage;
