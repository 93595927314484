import React, { Component, useRef } from "react";
import "./HomePage.css";
import "./Services.css";
import {
  Jumbotron,
  Image,
  Card,
  Col,
  Container,
  Row,
  CardDeck,
  Button,
} from "react-bootstrap";
import AOS from "aos";
import "./doctor-ipad.jpg";
import { Helmet } from "react-helmet";

class OurServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    //creates a reference for your element to use
    this.myDivToFocus = React.createRef();
    this.myDivToFocus2 = React.createRef();
  }

  handleOnClick = (event) => {
    //.current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  handleOnClick2 = (event) => {
    if (this.myDivToFocus2.current) {
      this.myDivToFocus2.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  render() {
    AOS.init();
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Services | PHYSIMAX LLC</title>
          <meta
            name="description"
            content="PHYSIMAX LLC provides a wide range of EMR filing services for health care providers"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Jumbotron
          fluid
          className="jumobtron jumbotron-title jumbotron-container jumbotron-hero-services vertical-center"
          style={{ marginBottom: "0px" }}
        >
          <Container
            className="jumbotron-container"
            fluid
            style={{ textAlign: "center" }}
          >
            <Col>
              <h1 className="jumbotron-title">Physimax LLC Services</h1>
              <h2 className="jumbotron-container-text">
                Simplifying provider workflows
              </h2>
            </Col>
            <Container
              fluid
              className="container-down-arrow"
              style={{ marginTop: "2vh" }}
            >
              <div>
                <Button
                  className="animation-solidify"
                  onClick={this.handleOnClick}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "1vh",
                    position: "relative",
                  }}
                >
                  <i style={{ fontSize: "5vh" }} class="fas fa-arrow-down"></i>
                </Button>
              </div>
            </Container>
          </Container>
        </Jumbotron>
        <div
          className="services-card-section"
          style={{
            background: "linear-gradient(315deg, #537895 0%, #09203f 74%)",
          }}
        >
          <Container
            fluid
            style={{
              marginTop: "0px",
              paddingTop: "4.5vh",
              paddingBottom: "10vh",
              height: "100vh",
              textAlign: "center",
              height: "inherit !important",
            }}
          >
            <h1
              style={{
                marginBottom: "4vh",
                color: "whitesmoke",
              }}
            >
              Census Tracking
            </h1>

            <CardDeck ref={this.myDivToFocus}>
              <Card style={{ borderRadius: "30px" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i style={{ color: "black" }} class="fas fa-magic"></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Automatic Notifications
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    Our Physician Support Specialist will monitor the census and
                    notify you when you have been consulted to see a new
                    patient.
                  </p>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "30px" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text ">
                      <i
                        style={{ color: "teal" }}
                        className="fas fa-notes-medical"
                      ></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Easy Daily Reports
                  </Card.Title>
                  <h3 className="center-text">-</h3>
                  <p className="center-text">
                    In addition, our Physician Support Specialist will provide
                    you with a daily report of new and established patients to
                    limit the time you spend retrieving and reviewing the census
                    each morning.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
          </Container>
          <Container
            fluid
            style={{
              marginTop: "0px",
              paddingTop: "4.5vh",
              paddingBottom: "10vh",
              height: "100vh",
              textAlign: "center",
              height: "inherit !important",
            }}
          >
            <h1
              style={{
                height: "10vh",
                color: "whitesmoke",
                marginBottom: "4vh",
              }}
            >
              In-Depth EMR Review
            </h1>

            <CardDeck>
              <Card style={{ borderRadius: "30px", marginBottom: "4vh" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i
                        style={{ color: "#d7ae9c" }}
                        class="fas fa-handshake"
                      ></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Easy Consulting of New Patients
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    Our Physician Support Specialist will provide detailed
                    report on new patient consults, including: the reason for
                    consult, pertinent past medical history, diagnoses since
                    admission as well as pertinent lab and imaging data.
                  </p>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "30px", marginBottom: "4vh" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i
                        style={{ color: "lightblue" }}
                        class="fas fa-search-plus"
                      ></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Thorough Review of Lab Data
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    Our Physician Support Specialist will review laboratory and
                    imaging data as well as consultant and hospitalist
                    documentation daily to stay informed of any changes in the
                    patient condition and note these changes in the medical
                    documentation.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
          </Container>
          <Container
            fluid
            style={{
              marginTop: "0px",
              paddingTop: "4.5vh",
              paddingBottom: "10vh",
              height: "100vh",
              textAlign: "center",
              height: "inherit !important",
            }}
          >
            <h1
              style={{
                height: "10vh",
                marginBottom: "4vh",
                color: "whitesmoke",
              }}
            >
              Medical Documentation
            </h1>

            <CardDeck>
              <Card style={{ borderRadius: "30px", marginBottom: "4vh" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i
                        style={{ color: "darkblue" }}
                        class="fas fa-info-circle"
                      ></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Highly Specific Documentation
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    Our Physician Support Specialists are highly educated
                    individuals that are trained in medical documentation
                    competency to ensure the highest degree of detail and
                    specificity when completing medical documentation on behalf
                    of our trusted providers.
                  </p>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "30px", marginBottom: "4vh" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i
                        style={{ color: "brown" }}
                        class="fas fa-folder-open"
                      ></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    A Complete Record
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    Our Physician Support Specialists are highly competent in
                    completion of medical documentation in its entirety,
                    including: History of Present Illness, Review of Systems,
                    Physical Exam, and Assessment and Plan.
                  </p>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "30px", marginBottom: "4vh" }}>
                <Card.Body>
                  <h2>
                    <div className="center-text">
                      <i style={{ color: "red" }} class="fas fa-clock"></i>
                    </div>
                  </h2>
                  <Card.Title className="text-center">
                    Delinquent Medical Record Tracking &amp; Resolution{" "}
                  </Card.Title>
                  <h3 class="center-text">-</h3>
                  <p class="center-text">
                    We will help you quickly rectify any delayed medical
                    records.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
            <Row style={{ padding: "1.5vh" }}></Row>
          </Container>
        </div>

        <div style={{ backgroundColor: "black", padding: "4vh" }}>
          <Container fluid mb-auto className="margin-bottomcustom">
            <Row>
              <Col style={{ color: "white" }}>
                <h2 style={{ marginBottom: "3vh", color: "white" }}>
                  Commited to Continuous Client Support
                </h2>
                <h4 style={{ marginBottom: "5vh" }}>
                  We are dedicated to diversifying our services to provide any
                  additional services to meet ongoing needs of our clients. We
                  are only a phone call or email away.
                </h4>
                <Button
                  size="lg"
                  style={{ padding: "1vh", margin: "3vh", marginLeft: "0px" }}
                  href="/contact"
                >
                  Contact Us <i class="fas fa-arrow-right"></i>
                </Button>{" "}
              </Col>
              <Col md={6} lg={6} sm={12} style={{ marginBottom: "4vh" }}>
                <Image
                  fluid
                  style={{ margin: "2vh" }}
                  src="https://images.pexels.com/photos/33999/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                ></Image>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default OurServices;
