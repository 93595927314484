import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "./Navbar.css"


export default function Nbar() {
  return (
    <Navbar bg="black justify-content-between" expand="lg" variant="dark" className="color-nav" sticky="top">
      <a class="navbar-brand" href="/">
        PHYSIMAX LLC
      </a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav class="ml-auto">
          <div class="container">
            <Nav.Link href="/"><div class='custom-link-text'>Home</div></Nav.Link>
            <Nav.Link href="/services"><div class='custom-link-text'>Services</div></Nav.Link>
            <Nav.Link href="/contact"><div class='custom-link-text'>Contact</div></Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
